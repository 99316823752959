import React from 'react';
import './MetaverseSection.css';
import mainVideo from '../assets/images/features/mcie-pr_3.mp4'; // 動画ファイル
import pngImage from '../assets/images/features/metaverse-image-02.png'; // PNG画像

const MetaverseSection = () => {
    return (
        <div className="container">
            <div className="text-center">
                <h2>Metaverse</h2>
                <p>世界と繋がる</p>
            </div>
            <div className="content-section">
                <div className="left-section">
                    <video className="main-video" autoPlay muted loop playsInline>
                    <source src={mainVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                    <img src={pngImage} alt="右下の画像" className="bottom-right-image" />
                </div>
                <div className="right-section">
                    <ul>
                    <li>都心、地方、海外から、<br />人々が一つのフィールドに集まります。</li>
                    <li>情報交換やチーム開発、<br />コミュニケーションが可能になります。</li>
                    <li>学習時だけでなく、<br />卒業後も継続的にメタバース空間を使えます。</li>
                    <li>入学と同時に学校のNFT学生証が配布されます。<br />学生証を持っている人しか入れない部屋があります。</li>
                    <li>資格試験に合格した方にスキル証明書が配布されます。<br />その証明書を持っていないと入れない部屋があります。</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default MetaverseSection;