import React from 'react';
import './FreelanceSection.css';
import freelanceImage1 from '../assets/images/workstyles/freelance-image-01.jpeg';
import freelanceImage2 from '../assets/images/workstyles/freelance-image-02.jpeg';
import freelanceImage3 from '../assets/images/workstyles/freelance-image-03.jpeg';
import freelanceImage4 from '../assets/images/workstyles/freelance-image-04.jpeg';
import freelanceImage5 from '../assets/images/workstyles/freelance-image-05.jpeg';

const freelanceData = [
    { point: '01', title: '好きな場所で働くことができる。', description: '自分の好きな場所で働くことができ、自由なライフスタイルを実現できます。', image: freelanceImage1 },
    { point: '02', title: '自由な時間で働くことができる。', description: '自分のペースで仕事を進めることができ、時間を有効に使えます。', image: freelanceImage2 },
    { point: '03', title: '仕事を受注することができる。', description: '一定のレベルに到達し、学校公認の資格を取得された方は、仕事を受け取ることができます。', image: freelanceImage3 },
    { point: '04', title: '大きな仕事も受け取れる。', description: '大規模案件が来た場合でも、信頼できる卒業生と一緒にチームを組むことができます。', image: freelanceImage4 },
    { point: '05', title: '半農半ITプログラム', description: '今話題のリジェネラティブ農業を行いながら、ITの複業をこなすことで、充実した生活を送ることができます。', image: freelanceImage5 }
];

const FreelanceSection = () => {
    return (
        <div className="freelance-section">
            <h2 className="freelance-title">Freelance</h2>
            <p className="freelance-description">フリーランスの方が安定して働くことのできる環境を用意しています。</p>
            {freelanceData.map((item, index) => (
            <div key={index} className="freelance-content">
                <div className="freelance-image-container">
                    <img src={item.image} alt={item.title} className="freelance-image" />
                </div>
                <div className="freelance-text">
                    <p className="point-text">・point {item.point}</p>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
            </div>
            ))}
        </div>
    );
};

export default FreelanceSection;