import React from 'react';
import './ReceiveAJobSection.css';
import receiveajobImage1 from '../assets/images/features/receiveajob-image-01.jpeg';
import receiveajobImage2 from '../assets/images/workstyles/freelance-image-02.jpeg';
import receiveajobImage3 from '../assets/images/workstyles/freelance-image-04.jpeg';
import receiveajobImage4 from '../assets/images/features/receiveajob-image-02.jpeg';
import receiveajobImage5 from '../assets/images/features/receiveajob-image-03.jpeg';

const receiveajobData = [
    { point: '01', title: 'フリーランス', description: 'フリーランスとして、場所と時間にとらわれることなく、働くことができます。', image: receiveajobImage1 },
    { point: '02', title: '複業・兼業', description: '家事育児をしながら、または農業をしながらなど、多様な働き方を実現します。', image: receiveajobImage2 },
    { point: '03', title: '卒業生同士の協業', description: '卒業生仲間が受け取った仕事を一緒にこなすことで、安定と安心が得られます。', image: receiveajobImage3 },
    { point: '04', title: '実績が実績を生む', description: '仕事をこなして実績をつけることで、さらに多くの仕事の機会を得ることができます。', image: receiveajobImage4 },
    { point: '05', title: '学校の営業チーム', description: 'メタバース情報工学学校の営業チームが、仕事を獲得します。', image: receiveajobImage5 }
];

const ReceiveAJobSection = () => {
    return (
        <div className="receive-a-job-section">
            <h2 className="receive-a-job-title">Accept A Job Order</h2>
            <p className="receive-a-job-description">仕事を受け取ることで、フリーランス、複業、兼業、様々な働き方が可能になります。</p>
            {receiveajobData.map((item, index) => (
            <div key={index} className="receive-a-job-content">
                <div className="receive-a-job-image-container">
                    <img src={item.image} alt={item.title} className="receive-a-job-image" />
                </div>
                <div className="receive-a-job-text">
                    <p className="point-text">・point {item.point}</p>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
            </div>
            ))}
        </div>
    );
};

export default ReceiveAJobSection;