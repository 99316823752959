// Curriculum.js

import React from 'react';
import './CurriculumSection.css';

const curriculumData = [
  { category: 'AI・機械学習', topics: ['Python', '機械学習概論', '機械学習実践', '生成AI活用＋開発'] },
  { category: 'データサイエンス', topics: ['Python', 'データサイエンス理論', 'データサイエンス実践', '統計学'] },
  { category: 'ゲーム開発・メタバース', topics: ['C#', 'Unity', 'AR/VR', 'ゲーム理論'] },
  { category: 'CG・アニメーション', topics: ['Blender', 'CG', 'アニメーション', 'ワールド作成'] },
  { category: 'Web開発', topics: ['HTML', 'CSS', 'JavaScript', 'React'] },
  { category: 'Web開発 + デザイン', topics: ['WordPress', 'Webデザイン', 'クリエイティブ', ''] },
  { category: 'サーバー＋α', topics: ['AWS', 'CLI', 'Linux', 'Django'] },
  { category: 'その他', topics: ['C', 'SQL', 'スマホアプリ', 'デジタルツイン'] },
  { category: 'その他', topics: ['DX', '情報基礎', 'IoT', 'Web3.0'] },
];

const CurriculumSection = () => {
  return (
    <div className="curriculum-section">
      <h2 className="curriculum-title">Curriculum</h2>
      <p>幅広く高度なスキルを習得できる動画が揃っています。</p>
      <div className="curriculum-table-wrapper">
        <table className="curriculum-table">
          <tbody>
            {curriculumData.map((row, index) => (
              <tr key={index}>
                <th className="category" data-label="カテゴリ">{row.category}</th>
                {row.topics.map((topic, idx) => (
                  <td key={idx} data-label={`コマ目${idx + 1}`}>{topic}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CurriculumSection;