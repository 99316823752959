import React from 'react';
import './FindWorkSection.css';
import companyLogo1 from '../assets/images/workstyles/findwork-image-01.jpeg';
import companyLogo2 from '../assets/images/workstyles/findwork-image-02.jpeg';
import companyLogo3 from '../assets/images/workstyles/findwork-image-03.jpeg';

const FindWorkSection = () => {
  return (
    <div className="find-work-section">
      <h2 className="find-work-title">Get a job</h2>
      <p className="find-work-description">様々な規模の企業に就職できます。</p>
      <div className="find-work-cards">
        <div className="find-work-card">
          <img src={companyLogo1} alt="Company 1" />
          <h3>大企業</h3>
          <p>安定した雇用とリモートワーク</p>
        </div>
        <div className="find-work-card">
          <img src={companyLogo2} alt="Company 2" />
          <h3>中小企業</h3>
          <p>新しいサービスを作るチャンス</p>
        </div>
        <div className="find-work-card">
          <img src={companyLogo3} alt="Company 3" />
          <h3>ベンチャー企業</h3>
          <p>挑戦的なプロジェクトで成長</p>
        </div>
      </div>
    </div>
  );
};

export default FindWorkSection;