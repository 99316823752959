import React from 'react';
import './CourseDetail.css'; // スタイルシートをインポート

import courseImage1 from '../assets/images/top/course-image-01.jpeg';
import courseImage2 from '../assets/images/top/course-image-02.jpeg';
import courseImage3 from '../assets/images/top/course-image-03.jpeg';
import courseImage4 from '../assets/images/top/course-image-04.jpeg';
import courseImage5 from '../assets/images/top/course-image-05.jpeg';
import courseImage6 from '../assets/images/top/course-image-06.jpeg';
import courseImage7 from '../assets/images/top/course-image-07.jpeg';
import courseImage8 from '../assets/images/top/course-image-08.jpeg';
import courseImage9 from '../assets/images/top/course-image-09.jpeg';



// 以降も各コースに対応する画像をインポート

const courses = [
    { id: 1, name: "AI開発者", description: "AIに関する開発技術を習得します。", image: courseImage1, details: ["Python", "機械学習概論", "機械学習実践", "生成AI開発活用", "実務実習"] },
    { id: 2, name: "データサイエンティスト", description: "データ分析技術を学び、データから価値を引き出します。", image: courseImage2, details: ["Python", "データサイエンス概論", "データサイエンス実践", "統計学", "実務実習"] },
    { id: 3, name: "ゲーム・アプリ開発者", description: "ゲームやアプリの設計・開発を実践的に学びます。", image: courseImage3, details: ["C#", "Unity実践", "ゲーム理論", "実務実習"] },
    { id: 4, name: "CG・アニメーションクリエイター", description: "CGやアニメーションの制作技術を学びます。", image: courseImage4, details: ["Blender", "レンダリング", "アニメーション", "実務実習"] },
    { id: 5, name: "メタバースクリエイター", description: "メタバース空間の構築や活用方法を学びます。", image: courseImage5, details: ["Blender", "レンダリング", "メタバース活用", "実務実習"] },
    { id: 6, name: "Webアプリ開発者", description: "Webアプリケーションの設計・開発を学びます。", image: courseImage6, details: ["HTML, CSS", "JavaScript, React", "Django", "実務実習"] },
    { id: 7, name: "Webサイトクリエイター", description: "Webサイトのデザインや開発スキルを学びます。", image: courseImage7, details: ["HTML, CSS", "JavaScript", "サーバー", "Webデザイン", "実務実習"] },
    { id: 8, name: "クリエイティブデザイン", description: "デザイン思考を取り入れたクリエイティブなスキルを身につけます。", image: courseImage8, details: ["デザイン", "PhotoShop", "Illustrator", "Adobe XD", "実務実習"] },
    { id: 9, name: "デジタルツインマスター", description: "デジタルツイン技術を活用したシステム構築を学びます。", image: courseImage9, details: ["Python", "機械学習概論", "機械学習実践", "C#", "Unity", "実務実習"] },
  ];
  
  const CourseDetail = () => {
    return (
      <div className="courses-section">
        <h2 className='course-section-title'>Course Detail</h2>
        <p className='course-section-title-p'>プロフェッショナルになるためのカリキュラムが揃っています。</p>

        
        {courses.map((course, index) => (
          <div
            className={`course-section-item ${index % 2 === 1 ? 'reverse' : ''}`} // 偶数番目は左右入れ替え
            key={course.id}
          >
            <div className="course-section-text">
              <h3>{course.name}</h3>
              <ul>
                {course.details.map((detail, index) => (
                  <li key={index}><i class="fa-solid fa-circle"></i>{detail}</li>
                ))}
              </ul>
            </div>
            <div className="course-section-image">
              {/* 画像の代わりに、コースIDを表示しているが、画像を追加する場合はsrcを設定 */}
              <img src={course.image} alt={course.name} />
            </div>
          </div>
        ))}

        <div className="course-section-notice">
          <h3>カスタマイズ</h3>
          <p>コース内容(動画の種類)は、ご自由に選択することができます。</p>
          <p>また、どのコースを選んでいただいても、追加の動画は見放題です。</p>
        </div>

      </div>
    );
  };
  

export default CourseDetail;