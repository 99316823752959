import React, { useEffect, useState } from 'react';
import './MeritSection.css'; // CSSファイル

import meritImage01 from '../assets/images/top/merit-image-01.png';
import meritImage02 from '../assets/images/top/merit-image-02.png';
import meritImage03 from '../assets/images/top/merit-image-03.png';
import meritImage04 from '../assets/images/top/merit-image-04.png';
import meritImage05 from '../assets/images/top/merit-image-05.png';
import meritImage06 from '../assets/images/top/merit-image-06.png';
import meritImage07 from '../assets/images/top/merit-image-07.png';
import smallImage01 from '../assets/images/top/small-icon-01.png'; // 小さな画像1
import smallImage02 from '../assets/images/top/small-icon-02.png'; // 小さな画像1
import smallImage03 from '../assets/images/top/small-icon-03.png'; // 小さな画像1
import smallImage04 from '../assets/images/top/small-icon-04.png'; // 小さな画像1
import smallImage05 from '../assets/images/top/small-icon-05.png'; // 小さな画像1

const MeritSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [deviceType, setDeviceType] = useState('desktop'); // 'mobile', 'tablet', 'desktop'のいずれか

  useEffect(() => {
    // 画面の幅をチェックし、デバイスタイプを設定
    const checkDeviceType = () => {
      if (window.innerWidth <= 480) {
        setDeviceType('mobile'); // スマホの場合
      } else if (window.innerWidth <= 768) {
        setDeviceType('tablet'); // iPad Miniの場合
      } else {
        setDeviceType('desktop'); // PCの場合
      }
    };

    // 初期のチェック
    checkDeviceType();

    // ウィンドウのリサイズ時にも再チェック
    window.addEventListener('resize', checkDeviceType);

    const handleScroll = () => {
      const imageContainer = document.querySelector('.image-container');
      const smallImages = document.querySelectorAll('.small-image');
      const triggerHeight = window.innerHeight / (deviceType === 'mobile' ? 1.5 : 1.8); // スマホの場合、少し早くトリガー
      const containerTop = imageContainer.getBoundingClientRect().top;
      const position = window.scrollY;
      setScrollPosition(position);
      console.log('Scroll Position:', position, 'Device Type:', deviceType);

      if (containerTop < triggerHeight) {
        smallImages.forEach((img, index) => {
          img.classList.add('fly-out');
          img.style.animationDelay = `${index * 0.2}s`;
          img.style.transition = `${index * 0.2}s`;
        });
      } else {
        smallImages.forEach((img) => {
          img.classList.remove('fly-out');
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkDeviceType);
    };
  }, [deviceType]);

  const getMerit2Image = () => {
    if (deviceType === 'mobile') {
      if (scrollPosition < 1880) {
        return meritImage02;
      } else if (scrollPosition >= 1880 && scrollPosition < 2020) {
        return meritImage03;
      } else {
        return meritImage04;
      }
    } else if (deviceType === 'tablet') {
      if (scrollPosition < 2050) {
        return meritImage02;
      } else if (scrollPosition >= 2050 && scrollPosition < 2350) {
        return meritImage03;
      } else {
        return meritImage04;
      }
    } else {
      // デスクトップの場合
      if (scrollPosition < 1600) {
        return meritImage02;
      } else if (scrollPosition >= 1600 && scrollPosition < 1700) {
        return meritImage03;
      } else {
        return meritImage04;
      }
    }
  };

  const getMerit3Image = () => {
    if (deviceType === 'mobile') {
      if (scrollPosition < 2700) {
        return meritImage05;
      } else if (scrollPosition >= 2700 && scrollPosition < 2850) {
        return meritImage06;
      } else {
        return meritImage07;
      }
    } else if (deviceType === 'tablet') {
      if (scrollPosition < 2850) {
        return meritImage05;
      } else if (scrollPosition >= 2850 && scrollPosition < 3150) {
        return meritImage06;
      } else {
        return meritImage07;
      }
    } else {
      // デスクトップの場合
      if (scrollPosition < 2300) {
        return meritImage05;
      } else if (scrollPosition >= 2300 && scrollPosition < 2400) {
        return meritImage06;
      } else {
        return meritImage07;
      }
    }
  };

  const smallImages = [smallImage01, smallImage02, smallImage03, smallImage04, smallImage05];

  return (
    <div className='merit-section'>
      <h2 className="merit-title">Merit</h2>
      <p>当校独自のメリットが数多く存在しています。</p>

      <div className="merit-item">
        <div className="content-left">
          <div className='inner-content'>
            <div className="number">
              1
            </div>
            <h3 className="text">
              実践的なスキル
            </h3>
            <div>
              <h4>
                プログラミングの書き方を覚えただけでは、何もできません！<br />
                実案件をこなすことで、以下のノウハウを得ることができます。<br />
              </h4>
              <ul>
                <li>環境構築</li>
                <li>Github</li>
                <li>サーバー構築</li>
                <li>チーム開発の経験</li>
                <li>プロジェクトマネジメント</li>
                <li>実務経験</li>
                <li>ポートフォリオ作成</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="image image-container">
          <img src={ meritImage01 } alt="メリットの画像" />
          {/* 小さい画像をまとめるコンテナ */}
          <div className="small-images-container">
            {smallImages.map((imageSrc, index) => (
              <img src={imageSrc} alt="小さな画像" className="small-image" />
            ))}
          </div>
        </div>
      </div>

      <div className="merit-item">
        <div className="content-left">
          <div className='inner-content'>
            <div className="number">
              2
            </div>
            <h3 className="text">
              自由な働き方
            </h3>
            <div>
              <h4>
                就職、起業、フリーランスなど、働き方の選択肢が広がります。<br />
                学校独自のサポートで、安定的な働き方を実現します。<br />
              </h4>
              <ul>
                <li>就職</li>
                <li>起業</li>
                <li>フリーランス</li>
                <li>複業・兼業</li>
                <li>リモートワーク</li>
                <li>ノマドワーク</li>
                <li>半農半ITプログラム</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="image image-container">
          <img src={ getMerit2Image() } alt="メリットの画像" />
        </div>
      </div>

      <div className="merit-item">
        <div className="content-left">
          <div className='inner-content'>
            <div className="number">
              3
            </div>
            <h3 className="text">
              オプション
            </h3>
            <div>
              <h4>
                独学や他の学校では得られないメリットがたくさんあります。<br />
                今までにない自分にレベルアップできます。<br />
              </h4>
              <ul>
                <li>NFTスキル証明書</li>
                <li>メタバース留学</li>
                <li>仕事が受け取れる</li>
                <li>起業支援プログラム</li>
                <li>メタバースキャンパスライフ</li>
                <li>自治体連携</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="image image-container">
          <img src={ getMerit3Image() } alt="メリットの画像" />
        </div>
      </div>

    </div>
  );
};

export default MeritSection;