import React from 'react';
import './CertificateSection.css';
import Certificate1 from '../assets/images/features/certificate-image-01.jpeg';
import Certificate2 from '../assets/images/features/certificate-image-02.jpeg';
import Certificate3 from '../assets/images/features/certificate-image-03.jpeg';

const FindWorkSection = () => {
  return (
    <div className="cirtificate-section">
      <h2 className="cirtificate-title">Certificate</h2>
      <p className="cirtificate-description">スキルの証明書を受け取ることができます。</p>
      <div className="cirtificate-cards">
        <div className="cirtificate-card">
          <img src={Certificate1} alt="Company 1" />
          <h3>スキルの証明</h3>
          <p>就職や仕事を受け取る時に有利になる、スキルの証明書を受け取ることができます。資格試験に合格することが条件となります。</p>
        </div>
        <div className="cirtificate-card">
          <img src={Certificate2} alt="Company 2" />
          <h3>NFT</h3>
          <p>NFTで受け取ることで、確実な証明として保有することができます。スマートフォンに入れて持ち歩くこともできます。</p>
        </div>
        <div className="cirtificate-card">
          <img src={Certificate3} alt="Company 3" />
          <h3>部屋の鍵</h3>
          <p>NFTとしてスマホ等のウォレットで管理し、メタバース空間やリアルな施設の中のセキュリティの高い部屋に入ることができます。</p>
        </div>
      </div>
    </div>
  );
};

export default FindWorkSection;