import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    email: '',
    confirmEmail: '',
    inquiryOptions: [],
    documentRequest: false,
    freeText: '',
  });

  const inquiryOptionsList = [
    "動画", "コース", "スキル証明書", "メタバース留学", "仕事の受け取り",
    "起業支援プログラム", "半農半ITプログラム", "自治体連携", "部活", "学園祭",
    "コンセプト", "費用", "補助金"
  ];

  const navigate = useNavigate(); // React RouterのuseHistoryフックを使用

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox' && name === 'inquiryOptions') {
      setFormData((prev) => {
        if (checked) {
          return { ...prev, inquiryOptions: [...prev.inquiryOptions, value] };
        } else {
          return {
            ...prev,
            inquiryOptions: prev.inquiryOptions.filter((item) => item !== value),
          };
        }
      });
    } else if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 確認ページに遷移して、フォームデータを渡す
    navigate('/contact-confirm', { state: { formData } }); // useNavigateで遷移
  };

  return (
    <div className="contact-form-container">
      <h1>お問い合わせ</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>名前</label>
          <div className="name-fields">
            <input
              type="text"
              name="lastName"
              placeholder="姓"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="firstName"
              placeholder="名"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>名前（フリガナ）</label>
          <div className="name-fields">
            <input
              type="text"
              name="lastNameKana"
              placeholder="セイ"
              value={formData.lastNameKana}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="firstNameKana"
              placeholder="メイ"
              value={formData.firstNameKana}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>メールアドレス</label>
          <input
            type="email"
            name="email"
            placeholder="メールアドレス"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>確認のため再度ご入力ください。</label>
          <input
            type="email"
            name="confirmEmail"
            placeholder="メールアドレス確認"
            value={formData.confirmEmail}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>問い合わせ内容（複数選択可）</label>
          <div className="checkbox-group">
            {inquiryOptionsList.map((option) => (
              <label key={option}>
                <input
                  type="checkbox"
                  name="inquiryOptions"
                  value={option}
                  onChange={handleChange}
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label>資料請求</label>
          <label className='resources'>
            <input
              type="checkbox"
              name="documentRequest"
              checked={formData.documentRequest}
              onChange={handleChange}
            />
            資料請求する
          </label>
        </div>

        <div className="form-group free-text">
          <label>自由記入欄</label>
          <textarea
            name="freeText"
            placeholder="ご自由にご記入ください。"
            value={formData.freeText}
            onChange={handleChange}
            rows="5"
          />
        </div>

        <button type="submit">送信</button>
      </form>
    </div>
  );
};

export default Contact;