import React from 'react';
import './VideoContentSection.css'; // スタイルシートをインポート

const VideoContentsSection = () => {
  const contents = [
    "Python", "機械学習概論", "機械学習実践", "生成AI活用＋開発", "データサイエンス理論",
    "データサイエンス実践", "統計学", "C#", "Unity", "AR/VR", "ゲーム理論", "Blender",
    "CG", "アニメーション", "ワールド作成", "HTML", "CSS", "JavaScript", "React", 
    "WordPress", "Webデザイン", "クリエイティブ", "AWS", "CLI", "Linux", "Django", 
    "C", "SQL", "スマホアプリ", "デジタルツイン", "DX", "情報基礎", "IoT", "Web3.0"
  ];

  return (
    <div className="video-contents">
      <h2 className='video-contents-title'>Video Contents</h2>
      <p>様々な動画コンテンツをご用意しております。</p>
      <div className="content-grid">
        {contents.map((content, index) => (
          <div className="content-item" key={index}>
            {content}
            {/* <div className="content-icon">
                <i className="fa-solid fa-chevron-right"></i>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoContentsSection;