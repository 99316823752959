import React from 'react';
import './ConceptSection.css';

// 画像をインポート
import regionalImage from '../assets/images/top/concept-image-01.jpeg';
import womenEmpowermentImage from '../assets/images/top/concept-image-02.jpeg';
import schoolAbsenceImage from '../assets/images/top/concept-image-03.jpeg';
import disparityCorrectionImage from '../assets/images/top/concept-image-04.jpeg';

const ConceptSection = () => {
  const concepts = [
    { id: 1, title: '地方創生', description: '地域の活性化を目指し、地方経済を支えるための取り組みを推進します。', image: regionalImage },
    { id: 2, title: '女性活躍', description: '女性の社会進出と活躍を支援し、男女平等な職場環境を整えます。', image: womenEmpowermentImage },
    { id: 3, title: '不登校', description: '不登校の子供たちに対して、適切なサポートと教育の機会を提供します。', image: schoolAbsenceImage },
    { id: 4, title: '格差是正', description: '世界に存在する教育格差を是正し、世界の平和を作ることを目指します。', image: disparityCorrectionImage },
  ];

  return (
    <div className='concept-section'>
      <h2 className='concept-title'>Concept</h2>
      <p>社会課題を解決することが当校のコンセプトです。</p>
      <div className='concept-grid'>
        {concepts.map((concept) => (
          <div key={concept.id} className='concept-item'>
            <img src={concept.image} alt={concept.title} className='concept-image' />
            <h3><i class="fa-solid fa-circle"></i>{concept.title}</h3>
            <p>{concept.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConceptSection;