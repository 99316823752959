import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // useNavigateに変更
import './ContactConfirm.css'; // 確認ページ用のスタイル

const ContactConfirm = () => {
  const location = useLocation(); // 送られてきたデータを取得
  const navigate = useNavigate(); // useHistoryの代わりにuseNavigateを使用
  const { formData } = location.state;

  const handleBack = () => {
    navigate(-1); // 修正する場合、前のページに戻る
  };

  const handleSend = async () => {
    try {
      const response = await fetch('http://localhost:3001/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData }),
      });

      if (response.ok) {
        alert('メールが送信されました！');
        navigate('/'); // トップページに遷移
      } else {
        alert('メール送信に失敗しました。');
      }
    } catch (error) {
      console.error('メール送信エラー:', error);
      alert('メール送信に失敗しました。');
    }
  };

  return (
    <div className="contact-confirm-container">
      <h1>確認ページ</h1>
      <p>以下の内容で送信しますか？</p>

      <div className="confirm-details">
        <p><strong>名前：</strong>{`${formData.lastName} ${formData.firstName}`}</p>
        <p><strong>名前（フリガナ）：</strong>{`${formData.lastNameKana} ${formData.firstNameKana}`}</p>
        <p><strong>メールアドレス：</strong>{formData.email}</p>
        <p><strong>問い合わせ内容：</strong>{formData.inquiryOptions.join(', ')}</p>
        <p><strong>資料請求：</strong>{formData.documentRequest ? 'あり' : 'なし'}</p>
        <p><strong>自由記入欄：</strong>{formData.freeText}</p>
      </div>

      <div className="confirm-buttons">
        <button className='fixes' onClick={handleBack}>修正する</button>
        <button onClick={handleSend}>送信する</button>
      </div>
    </div>
  );
};

export default ContactConfirm;