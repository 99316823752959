import React from 'react';
import './Footer.css';
import footerLogo from '../assets/images/top/logo-icon7.png';


const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-logo">
          {/* ロゴと大学名 */}
          <img src={footerLogo} alt="学校ロゴ" className="logo" />
          <div className="college-info">
            <h2>メタバース情報工学学校</h2>
            <p>〒451-0045 愛知県名古屋市西区名駅2-4-3</p>
          </div>
        </div>

        <div className="footer-links">
          {/* フッターリンク */}
          <a href="https://forms.gle/cWVuRnb6TcD43jLk9">資料請求・お問い合わせ</a>
          <a href="/learning-content">学習内容</a>
          <a href="/work-styles">働き方</a>
          <a href="/features">特徴</a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>Copyright© Metaverse College Of Infomation Engineering All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;