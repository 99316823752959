import React from "react";
import "./ExperienceSection.css"; // スタイルシートをインポート

import experienceImage1 from "../assets/images/features/experience-image-01.jpeg";
import experienceImage2 from "../assets/images/workstyles/freelance-image-04.jpeg";
import experienceImage3 from "../assets/images/workstyles/findwork-image-01.jpeg";
import experienceImage4 from "../assets/images/features/experience-image-03.jpeg";
import experienceImage5 from "../assets/images/features/experience-image-04.jpeg";

// 以降も各コースに対応する画像をインポート

const experiences = [
  {
    id: 1,
    name: "未経験からの転職",
    description:
      "未経験からでも転職することができ、その後は即戦力として現場で活躍できる力を身につけることができます。技術スキルだけでなく、実務での応用力やコミュニケーション能力も養えるので、チームの一員として迅速にプロジェクトに参加できます。新しい環境での挑戦にも対応できるため、スムーズな転職が可能です。",
    image: experienceImage1,
  },
  {
    id: 2,
    name: "独学では難しいチーム開発の経験",
    description:
      "独学や通常のプログラミングスクールでは、チームでのGithubの使用方法やサービスの公開方法などを自分で勉強しなければなりません。当校のチーム開発ではこれらの実務経験を積むことができ、プロフェッショナルな開発プロセスを学べます。開発の流れを一貫して理解し、効果的なコミュニケーションを通じてプロジェクトを成功させるためのスキルを養うことができます。",
    image: experienceImage2,
  },
  {
    id: 3,
    name: "リモートワークで高収入",
    description:
      "スキルがあれば、リモートワークで場所を選ばずに働くことが可能になります。効率的な作業環境を自宅で整え、成果物を通じて自分のスキルを証明することで、フレキシブルな働き方が実現します。大きな企業で安定的に働きながら、通勤の時間を短縮できることがリモートワークの大きな魅力です。",
    image: experienceImage3,
  },
  {
    id: 4,
    name: "0から作る力",
    description:
      "1人でアイデアを形にし、全ての工程を自分で完遂する力があれば、フリーランスとして独立し、収入を得ることができます。課題解決方法の立案から要件定義の作成、実装、そして最終的なローンチまでを自身で行えるスキルは大きなアドバンテージであり、独立した働き方の選択肢を増やします。クライアントとの取引でも、信頼を得ることができるでしょう。",
    image: experienceImage4,
  },
  {
    id: 5,
    name: "起業が容易",
    description:
      "起業を考える際、自社サービスを自分の手で作り上げることができるのは、大きな強みです。外注費用を削減しつつ、高品質なサービスを提供できるため、初期コストを抑えながらも市場で競争力を保つことができます。また、自分の理想通りのサービスを素早く提供できるので、起業に向けた道のりが非常にスムーズになります。",
    image: experienceImage5,
  },
];

const ExperienceSection = () => {
  return (
    <div className="experience-section">
      <h2 className="experience-section-title">Experience</h2>
      <p className="experience-section-title-p">
        実務経験を積むことができます。独学では難しい、チーム開発に関わる全てを経験することができます。
      </p>

      {experiences.map((experience, index) => (
        <div
          className={`experience-section-item ${
            index % 2 === 1 ? "reverse" : ""
          }`} // 偶数番目は左右入れ替え
          key={experience.id}
        >
          <div className="experience-section-text">
            <h3>{experience.name}</h3>
            <p>{experience.description}</p>
          </div>
          <div className="experience-section-image">
            {/* 画像の代わりに、コースIDを表示しているが、画像を追加する場合はsrcを設定 */}
            <img src={experience.image} alt={experience.name} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExperienceSection;
