import React from 'react';
import './ContactButtonSection.css';
// import { Link } from 'react-router-dom';

const ContactButtonSection = () => {
  return (
    <div className="contact-button-section">
      <div className="button-container">
        {/* 外部リンク */}
        <a href="https://lin.ee/4z5c3KI" target="_blank" rel="noopener noreferrer" className="contact-button">
          <h2 className="large-text">LINEで相談</h2>
        </a>
        {/* 内部リンク */}
        {/* <Link to="/contact" className="contact-button"> */}
        <a href="https://forms.gle/ZCVbDRcB7TXLSGiM8" target="_blank" rel="noopener noreferrer" className="contact-button">
          <h2 className="large-text">お問い合わせ</h2>
        </a>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default ContactButtonSection;