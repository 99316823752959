import React from 'react';

const Corporate = () => {
  return (
    <div>
      <h1>法人</h1>
      <p>ここでは、プログラミングやAI、Web開発の学習内容について紹介します。</p>
    </div>
  );
};

export default Corporate;