import React from 'react';
import './CourseSection.css';
import { useNavigate } from 'react-router-dom';

// 画像をインポート
import aiImage from '../assets/images/top/course-image-01.jpeg';
import dataScienceImage from '../assets/images/top/course-image-02.jpeg';
import gameDevImage from '../assets/images/top/course-image-03.jpeg';
import cgImage from '../assets/images/top/course-image-04.jpeg';
import metaverseImage from '../assets/images/top/course-image-05.jpeg';
import webAppImage from '../assets/images/top/course-image-06.jpeg';
import webDesignImage from '../assets/images/top/course-image-07.jpeg';
import creativeDesignImage from '../assets/images/top/course-image-08.jpeg';
import digitalTwinImage from '../assets/images/top/course-image-09.jpeg';

const CourseSection = () => {
  const navigate = useNavigate();

  const courses = [
    { id: 1, title: 'AI開発者', image: aiImage },
    { id: 2, title: 'データサイエンティスト', image: dataScienceImage },
    { id: 3, title: 'ゲーム・アプリ開発者', image: gameDevImage },
    { id: 4, title: 'CG・アニメーションクリエイター', image: cgImage },
    { id: 5, title: 'メタバースクリエイター', image: metaverseImage },
    { id: 6, title: 'Webアプリ開発者', image: webAppImage },
    { id: 7, title: 'Webサイトクリエイター', image: webDesignImage },
    { id: 8, title: 'クリエイティブデザイン', image: creativeDesignImage },
    { id: 9, title: 'デジタルツインマスター', image: digitalTwinImage },
  ];

  const handleCourseClick = (id) => {
    navigate(`/courses/${id}`);
  };

  return (
    <div className='course-section'>
      <h2 className='course-title'>Courses</h2>
      <p>様々なコースから自分に合ったものを選ぶことができます。</p>
      <div className='course-grid'>
        {courses.map((course) => (
          <div 
            key={course.id} 
            className='course-item' 
            onClick={() => handleCourseClick(course.id)}
          >
            <img src={course.image} alt={course.title} className='course-image' />
            <div className='course-overlay'>
              <h2 className='course-title-text'>{course.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseSection;